import { is, pickBy } from 'ramda';
import { isEqual } from 'lodash';
import { polyglot, getTranslationArray } from '../polyglot';
import {
  getCarMapValues,
  getCountryNameByCode,
  getFormattedTimeValue,
  getFormattedDateValue,
  getFormattedTypeOfUploadedDocuments,
} from '../../services/claimData/claimData.helpers';

export const YES_VALUE = 'j';
export const NO_VALUE = 'n';
export const PARTLY_VALUE = 'Teilweise';
export const YES_LIMITED = 'tlw';
export const YES_BOOLEAN_VALUE = '1';
export const NO_BOOLEAN_VALUE = '0';
export const COMPANY_SALUTATION = 'Firma';
export const userTypes = {
  OWNER: 'owner',
  THIRD_PARTY: 'thirdParty',
};

export const claimsKeys = {
  WHO_FILLS: 'SMelder',
  WHAT_HAPPENED: 'GeVoSTitel',
  owner: {
    SALUTATION: 'AnredeSMelder',
    FIRST_NAME: 'VornameSMelder',
    LAST_NAME: 'NachnameSMelder',
    PHONE: 'TelNrSMelder',
    EMAIL: 'EMailSMelder',
    TRACKER_AGREEMENT: 'TrackingKZ',
    ADDRESS: 'StrasseSMelder',
    POST_CODE: 'PLZSMelder',
    CITY: 'OrtSMelder',
    COUNTRY_CODE: 'LandSMelder',
    LICENSE_NUMBER: 'AKZVN',
    CAR_MAP: {
      ABereichVLVN: 'front-left',
      ABereichVMVN: 'front-middle',
      ABereichVRVN: 'front-right',
      ABereichLSMVN: 'middle-left',
      ABereichDVN: 'middle-part',
      ABereichRSMVN: 'middle-right',
      ABereichHLVN: 'back-left',
      ABereichHMVN: 'back-middle',
      ABereichHRVN: 'back-right',
    },
    DESCRIBE_DAMAGE: 'FahrzeugschadenVN',
  },
  IS_READY_FOR_DRIVING: 'FzgFahrbereitKzVN',
  AIRBAGS_WERE_DEPLOYED: 'FzgAirbagGeloestKzVN',
  WARNING_LIGHTS_ACTIVATED: 'FzgWarnleuchtenKzVN',
  FLUIDS_LOST: 'FzgFluessigkeitenKzVN',
  customer: {
    SALUTATION: 'AnredeVN',
    FIRST_NAME: 'VornameVN',
    LAST_NAME: 'NachnameVN',
    PHONE: 'TelNrVN',
    EMAIL: 'EMailVN',
    ADDRESS: 'StrasseVN',
    POST_CODE: 'PLZVN',
    CITY: 'OrtVN',
    COUNTRY_CODE: 'LandVN',
  },
  broker: {
    SALUTATION: 'AnredeSMakler',
    FIRST_NAME: 'VornameSMakler',
    LAST_NAME: 'NachnameSMakler',
    PHONE: 'TelNrSMakler',
    EMAIL: 'EMailSMakler',
    REFERENCE: 'BrokerZeichen',
  },
  KIND_OF_DAMAGE: 'SArtKz',
  ANIMAL_DAMAGE_KIND: 'ZusammenstossMitTierKz',
  DATE: 'SDatum',
  TIME: 'SUhrzeit',
  LOCATION: 'SOrt',
  LOCATION_STREET: 'SOrtStrasse',
  COUNTRY_CODE: 'SOrtLand',
  POLICY_NUMBER: 'VersNummer',
  POLICY_NUMBER_SECOND: 'VersNummer2',
  CIRCUMSTANCES: 'SHergangBeschr',
  FURTHER_INFORMATION: 'WeitereSAngaben',
  NEW_FURTHER_INFORMATION: 'WeitereSAngaben2',
  PRIVATE_VEHICLE: 'VorsteuerVNKz',
  THIRD_PARTY_PRIVATE_VEHICLE: 'VorsteuerAST1Kz',
  LEASED_FINANCED: 'GeleastFinanziert',
  DEPARTMENT: 'NamePol',
  POLICE: 'PolizeimeldungKz',
  DRIVER: 'FahrerVN',
  driver: {
    FIRST_NAME: 'VornameFahrerVN',
    LAST_NAME: 'NachnameFahrerVN',
    PHONE: 'TelNrFahrerVN',
  },

  WAS_REPORTED: 'WildschadenMeldKz',
  CASE_NUMBER: 'AktenzeichenPol',
  ANY_WITNESS: 'ZeugenKz',
  IS_REPAIRED: 'ReparaturKz',
  BANK_ACCOUNT: 'BankverbindungKz',
  ANOTHER_ACCOUNT: 'Anderes Konto',
  MY_ACCOUNT: 'Beitragskonto',
  IBAN_NAME: 'NachnameZE1',
  IBAN: 'IBAN1',
  INVOICES: 'RechnungKz',
  DAMAGE_REPAIR: 'SchadenbehebungKz',
  REPAIR: 'Reparatur',
  PAYOUT: 'Auszahlung',
  WSPID_WORKSHOP: 'WSPIDWerkstatt',
  REPAIR_SHOP_NAME: 'FirmennameWerkstatt',
  RECOMMEND_REPAIR_SHOP: 'EmpfehlungKz',
  SUB_SECTOR_INSURED_PERSON: 'BetrUntersparten',
  WORKSHOP: 'WerkstattKz',
  WORKSHOP_ADDRESS: 'StrasseWerkstatt',
  WORKSHOP_PHONE: 'TelNrWerkstatt',
  WORKSHOP_CITY: 'OrtWerkstatt',
  WORKSHOP_POSTAL_CODE: 'PLZWerkstatt',
  HAS_FILES: 'WeitereDokumenteKz',
  TYPE_FILES: 'UploadGevo',
  FILES: 'documents',
  firstWitness: {
    SALUTATION: 'AnredeZeuge1',
    FIRST_NAME: 'VornameZeuge1',
    LAST_NAME: 'NachnameZeuge1',
    PHONE: 'TelNrZeuge1',
    ADDRESS: 'StrasseZeuge1',
    POST_CODE: 'PLZZeuge1',
    CITY: 'OrtZeuge1',
    COUNTRY_CODE: 'LandZeuge1',
  },
  secondWitness: {
    SALUTATION: 'AnredeZeuge2',
    FIRST_NAME: 'VornameZeuge2',
    LAST_NAME: 'NachnameZeuge2',
    PHONE: 'TelNrZeuge2',
    ADDRESS: 'StrasseZeuge2',
    POST_CODE: 'PLZZeuge2',
    CITY: 'OrtZeuge2',
    COUNTRY_CODE: 'LandZeuge2',
  },
  thirdWitness: {
    SALUTATION: 'AnredeZeuge3',
    FIRST_NAME: 'VornameZeuge3',
    LAST_NAME: 'NachnameZeuge3',
    PHONE: 'TelNrZeuge3',
    ADDRESS: 'StrasseZeuge3',
    POST_CODE: 'PLZZeuge3',
    CITY: 'OrtZeuge3',
    COUNTRY_CODE: 'LandZeuge3',
  },
  thirdParty: {
    DRIVER_FIRST_NAME: 'VornameFahrerAST1',
    DELIVER_DAMAGED_ITEM: 'GegenstandBearbeitet1',
    ITEM_DELIVERED: 'GegenstandGeliefert1',
    DRIVER_LAST_NAME: 'NachnameFahrerAST1',
    DRIVER_PHONE: 'TelNrFahrerAST1',
    SALUTATION: 'AnredeAST1',
    FIRST_NAME: 'VornameAST1',
    LAST_NAME: 'NachnameAST1',
    PHONE: 'TelNrAST1',
    EMAIL: 'EMailAST1',
    TRACKER_AGREEMENT: 'TrackingKZ',
    ADDRESS: 'StrasseAST1',
    POST_CODE: 'PLZAST1',
    CITY: 'OrtAST1',
    COUNTRY_CODE: 'LandAST1',
    LICENSE_NUMBER: 'AKZAST1',
    PERSONAL_INJURY: 'VerletzungenKzAST1',
    PERSONAL_INJURY_DESC: 'VerletzungenAST1',
    PERSONAL_INJURIES: 'VerletzungenAST1',
    ITEM_DAMAGE: 'SachschadenKzAST1',
    ITEM_DAMAGE_DESC: 'SachschadenAST1',
    ITEM_INJURIES: 'BeschObjektAST1',
    VEHICLE_DAMAGE: 'KFZSchadenKzAST1',
    VEHICLE_TYPE: 'FahrzeugartKzAST1',
    OBJECTS_LENDING: 'GegenstandGeliehen1',
    EMPLOYED_AT: 'AST1BeschaeftigtBeiVN',
    BUSINESS_PARTNER: 'ArgePartnerKz',
    ANIMAL_INJURY: 'animal-injury',
    ANIMAL_INJURIES: 'VerletzungenTier',
    IS_READY_FOR_DRIVING: 'FzgFahrbereitKzAST1',
    AIRBAGS_WERE_DEPLOYED: 'FzgAirbagGeloestKzAST1',
    WARNING_LIGHTS_ACTIVATED: 'FzgWarnleuchtenKzAST1',
    FLUIDS_LOST: 'FzgFluessigkeitenKzAST1',
    CAR_MAP: {
      ABereichVLAST1: 'front-left',
      ABereichVMAST1: 'front-middle',
      ABereichVRAST1: 'front-right',
      ABereichLSMAST1: 'middle-left',
      ABereichDAST1: 'middle-part',
      ABereichRSMAST1: 'middle-right',
      ABereichHLAST1: 'back-left',
      ABereichHMAST1: 'back-middle',
      ABereichHRAST1: 'back-right',
    },
    DESCRIBE_DAMAGE: 'FahrzeugschadenAST1',
    coinsured_contact_data: {
      SALUTATION: 'AnredeMitVersPerson',
      FIRST_NAME: 'VornameMitVersPerson',
      LAST_NAME: 'NachnameMitVersPerson',
      COMPANY_NAME: 'FirmennameMitVersPerson',
      PHONE: 'TelNrMitVersPerson',
      EMAIL: 'EMailMitVersPerson',
      ADDRESS: 'StrasseMitVersPerson',
      POST_CODE: 'PLZMitVersPerson',
      CITY: 'OrtMitVersPerson',
      COUNTRY_CODE: 'LandMitVersPerson',
    },
  },
  VEHICLE_TYPE: 'FahrzeugartKzVN',
  vehicleType: {
    CAR: 'PKW',
    TRUCK: 'LKW',
    MOTORCYCLE: 'Motorrad',
    OTHER: 'Sonstiges',
  },
  SETTLEMENT: 'FiktAbrechnungKz',
  ITEM_DELIVERED: 'GegenstandGeliefert1',
  CAUSER_OF_DAMAGE: 'Schadenverursacher',
  DAMAGED_PARTS: 'FahrzeugschadenVN',
  USE_CALCULATION_APP: 'ERGOKalkAppKz',
  USE_KVA_SERVICE: 'KVAServiceKz',
  KVA_SERVICE_WORKSHOP: 'KVAWerkstattKz',
  KVA_SERVICE_WORKSHOP_CITY: 'KVAOrtWerkstatt',
  KVA_SERVICE_WORKSHOP_ADDRESS: 'KVAStrasseWerkstatt',
  KVA_SERVICE_WORKSHOP_PLZ: 'KVAPLZWerkstatt',
  KVA_SERVICE_WORKSHOP_NAME: 'KVAFirmennameWerkstatt',
  CALCULATIONAPP_PHONE: 'TelNrKalkApp',
  CALCULATIONAPP_EMAIL: 'EMailKalkApp',
  PAGE_TYPE: 'Seitenart',
  HAIL_DAMAGE_REPORT: '5949-HagelSchadenmeldung-FORM',
  PERSON_DAMAGE_REPORT: '5830-PersonenSchadenmeldung-FORM',
  DAMAGE_REPORT: '5800-Schadenmeldung-FORM',
  SUBSEQUENT_DAMAGE_REPORT: '5900-SchadenFolgemeldung-FORM',
  BICYCLE_THEFT_DAMAGE_REPORT: 'FahrradSchadenmeldung-FORM',
  CATEGORY: 'SKategorieKz',
  SUBSEQUENT_UPLOAD: 'Upload-Folgepost',
  YOUR_MESSAGE: 'SonstSchdHinw',
  hospital: {
    TREATMENT: 'StatBehandlungKz',
    NAME: 'NachnameEB1',
    ADDRESS: 'StrasseEB1',
    POST_CODE: 'PLZEB1',
    CITY: 'OrtEB1',
    COUNTRY: 'LandEB1',
    INITIAL_TREATMENT: 'WBgleichEBKz',
    INITIAL_THERAPY_DATE: 'StatBehandlungBeginn',
    INITIAL_THERAPY_DATE_END: 'StatBehandlungEnde',
  },
  injured: {
    SALUTATION: 'AnredeVPerson',
    FIRST_NAME: 'VornameVPerson',
    LAST_NAME: 'NachnameVPerson',
    PHONE: 'TelNrVPerson',
    ADDRESS: 'StrasseVPerson',
    STREET_NUMBER: 'HausNrVPerson',
    POST_CODE: 'PLZVPerson',
    CITY: 'OrtVPerson',
    COUNTRY: 'LandVPerson',
    BIRTH_DATE: 'GebDatumVPerson',
    EMAIL: 'EMailVPerson',
    FIRST_AID_DATE: 'ErstbehandlungVPerson',
    IS_REPORTER: 'IstVPersonMelder',
    HOUSE_COMMUNITY: 'HausGemeinschaft',
    CONSENT: 'ZustimmungVP',
  },
  assetLiability: {
    JOB_DESCRIPTION: 'BerufsgruppeKd',
    OWN_OR_THIRD_PARTY: 'BetrUntersparten',
    DUNNING_NOTICE: 'KlageMahnKz',
    DUNNING_NOTICE_DATE: 'KlageMahnDatum',
    ASSERTED_IN_WRITING: 'AnspruchSchriftKz',
    AMOUNT_OF_DAMAGE: 'SHöhe',
    ACCUSATIONS_ARE_CORRECT: 'VorwurfZutreffendKz',
    STATEMENT_ON_ACCUSATION: 'Stellungnahme',
    STATEMENT_ON_ACCUSATION_KZ: 'StellungnahmeKz',
  },
  accident: {
    TYPE: 'UArt',
    LOCATION_TYPE: 'UUArt',
    SITUATION: 'UUrsache',
    TYPE_DETAILS: 'DetailUrsache',
    WHO_WAS_INJURED: 'VPersonWarFahrer',
    INJURY_DESCRIPTION: 'VerletzungenVPerson',
    ALCOHOL_INTAKE: 'AlkDrogenVPersonKz',
    GOT_ICD_CODE: 'ICDCodeKz',
    ICD_CODES: {
      ICD_CODE_1: 'ICD1',
      ICD_CODE_2: 'ICD2',
      ICD_CODE_3: 'ICD3',
      ICD_CODE_4: 'ICD4',
      ICD_CODE_5: 'ICD5',
      ICD_CODE_6: 'ICD6',
      ICD_CODE_7: 'ICD7',
      ICD_CODE_8: 'ICD8',
      ICD_CODE_9: 'ICD9',
      ICD_CODE_10: 'ICD10',
    },
  },
  legalProtection: {
    RELATION: 'BeziehungMV',
    RELATION_DESCRIPTION: 'BeziehungMVSonst',
    CATEGORY: 'SKategorieKz',
    HOLDER: 'HalterSL',
    PRIMARY_RESIDENCE: 'ImmoErstwohnsitz',
    ADDRESS: 'ImmoOrtStrasse',
    POST_CODE: 'ImmoOrtPLZ',
    CITY: 'ImmoOrtOrt',
    COUNTRY: 'ImmoOrtLand',
    HERITAGE_ADVICE: 'ErbBeratung',
  },
  property: {
    DIVISION: 'USparte',
    BUILDING_OWNER: 'GebaudeEigentumer',
    DAMAGE_LOCATION: 'ROrtKz',
    CASE: 'SUrsacheKz',
    RESULT_STRUCTURE: 'SHergangStrukt',
    CAUSE_NOTE: 'SUrsacheAnKz',
    VALUE_INDICATION: 'WertangabeKz',
    LIST_OF_STOLEN_GOODS: 'StehlgutlisteKz',
    DAMAGE_DESCRIPTION: 'SBeschr',
    OBJECT_DESCRIPTION: 'BeschObjekt',
    STOLEN_OBJECT: 'GestohlenObjekt',
    FLOOR_WALL_COVERING: 'BodenWandBelag',
    WAS_LOCKED: 'FahrradGesichertKz',
    WHAT_WAS_STOLEN: 'FahrradTeilentwendungKz',
    IS_EBIKE: 'FahrradTyp',
    PRICE: 'Kaufpreis',
    INVOICE_AVAILABLE: 'AnschaffungsBelegKz',
    MODEL_DESCRIPTION: 'ModellBezeichnung',
    listOfStolenGoods: {
      GOODS_1: 'Stehlgutliste1',
      GOODS_2: 'Stehlgutliste2',
      GOODS_3: 'Stehlgutliste3',
      GOODS_4: 'Stehlgutliste4',
      GOODS_5: 'Stehlgutliste5',
    },
    damageValue: {
      VALUE_1: 'Wertangaben1',
      VALUE_2: 'Wertangaben2',
      VALUE_3: 'Wertangaben3',
      VALUE_4: 'Wertangaben4',
      VALUE_5: 'Wertangaben5',
    },
    EXECUTION_OF_REPAIRS: 'Reparaturausfuehrung',
    WHO_FILLS: 'KundenTypKz',
    PRIVATE: 'Privatkunde',
    BUSINESS: 'Geschäftskunde',
    // Business Property
    WHICH_FLOOR: 'Etage',
    OPERATIONS: 'GeschaeftsAusfallKz',
    TAX_FOR_DAMAGE: 'VorsteuerVNKz',
    BUILDING_ACCESSORIES: 'ZubehoerKz',
    DAMAGED_BUILDING_INSIDE_OUTSIDE: 'SBeschrKz',
  },
  upload: {
    CLAIM_NUMBER: 'SNr',
    IDENTITY: 'UploadKommentar',
    ADDITIONAL_INFO: 'UploadKommentar',
  },
  collisionAbroad: {
    thirdParty: {
      COUNTRY_CODE: 'AKZLandAST1',
    },
    owner: {
      COUNTRY_CODE: 'AKZLand',
    },
  },
  LANDING_PAGE_SRC: 'LandingPageSrc',
};

export const compareValues = (firstValue, secondValue = YES_VALUE) => isEqual(firstValue, secondValue);

export const isLiabilityFlow = data => !compareValues(data[claimsKeys.WHO_FILLS]);

export const isCurrentUserFlow = (value, flowName) => compareValues(value, flowName);

export const isOtherPrivateClient = (value, whoFills) => compareValues(value, whoFills);

// ToDo: delete
export const isPrivateVehicle = data => compareValues(data[claimsKeys.PRIVATE_VEHICLE]);

export const isItemDamage = data => compareValues(data[claimsKeys.thirdParty.ITEM_DAMAGE]);

export const isPersonalInjury = data => compareValues(data[claimsKeys.thirdParty.PERSONAL_INJURY]);

export const isVehicleDamage = data => compareValues(data[claimsKeys.thirdParty.VEHICLE_DAMAGE]);

export const isRepaired = data => compareValues(data[claimsKeys.IS_REPAIRED]);

export const isUsingCalculationApp = data => compareValues(data[claimsKeys.USE_CALCULATION_APP]);

export const isOnlyThirdPartVehicleDamage = data => (
  compareValues(data[claimsKeys.thirdParty.VEHICLE_DAMAGE])
    && !isPersonalInjury(data)
    && !isItemDamage(data)
);

// ToDo: delete
export const isCompany = key => data => {
  const companyValue = getTranslationArray('common.salutation').company;
  return compareValues(data[key], companyValue);
};

export const getTime = value => value && getFormattedTimeValue(value);

export const getDate = value => (value && getFormattedDateValue(value)) || '';

export const getCountryName = code => getCountryNameByCode(code);

export const getLicenseNumber = licenseNumber => (licenseNumber ? licenseNumber.split('-').join(' ') : '');

export const getIban = iban => `*******${iban.slice(-4)}`;

export const getParticularData = (newKey, value) => ({ [newKey]: value || '' });

export const getParticularDataIfExist = (localePath, path, newKey, value) => ({
  [newKey]: value ? polyglot.t(`${localePath}.${path}`, getParticularData(newKey, value)) : '',
});

export const getAdditionalDamageDetailsText = (localePath, path, value) => {
  const status = polyglot.t(`${localePath}.vehicle-damage.${path}.${value}`);
  return `${polyglot.t(`${localePath}.vehicle-damage.${path}.title`, { status })} `;
};

export const getCarMapInfo = (data, userType = userTypes.OWNER) => (
  Object.keys(claimsKeys[userType].CAR_MAP)
    .filter(key => data[key] === YES_VALUE)
    .map(key => getCarMapValues(claimsKeys[userType].CAR_MAP[key]))
    .join(', ')
);

export const getCarMapKey = (value, userType = userTypes.OWNER) => {
  const data = claimsKeys[userType].CAR_MAP;
  return Object.keys(data).find(key => data[key] === value);
};

export const getIcdCode = (data) => {
  const mappingObject = {};
  data.map((val, index) => {
    mappingObject[claimsKeys.accident.ICD_CODES[`ICD_CODE_${index + 1}`]] = val;
    return (mappingObject);
  });
  return mappingObject;
};

export const getFileInfo = (data, localePath) => (
  getFormattedTypeOfUploadedDocuments(data[claimsKeys.TYPE_FILES])
    .map(documentKey => polyglot.t(`${localePath}.docs.${documentKey}`))
    .filter(v => v !== '')
    .join(', ')
);

export const getFileInfoBeforeMapped = (data, localePath) => (
  data
    .map(documentKey => polyglot.t(`${localePath}.docs.${documentKey}`))
    .join(', ')
);

export const pickFormDataForStorage = source => pickBy(
  value => !(is(Object, value) && value.doNotSaveInStorage),
  source,
);
