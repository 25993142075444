import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import injectJss from 'react-jss';
import { isEmpty } from 'lodash';
import Modal from '@eg/elements/Modal';
import Button from '@eg/elements/Button';
import SvgIcon from '@eg/elements/SvgIcon';
import { ErgoElementsContext } from '@eg/elements/Provider';
import { logoIcon } from '../../helpers/icons';
import handleOnUnload from '../../helpers/handleOnUnload';
import styles from './styles.jss';


class Logo extends React.PureComponent {
  state = {
    showDialog: false,
  }

  clickHandler = () => {
    const { formData } = this.props;
    const { showDialog } = this.state;
    window.removeEventListener('beforeunload', handleOnUnload);
    if (isEmpty(formData.vehicle)) {
      window.location = 'https://www.ergo.de/';
    } else {
      this.setState({ showDialog: !showDialog });
    }
  };

  cancelHandler = () => {
    this.setState({ showDialog: false });
    window.addEventListener('beforeunload', handleOnUnload);
  };

  confirmHandler = () => {
    window.location = 'https://www.ergo.de/';
    this.setState({ showDialog: false });
  };

  render() {
    const { classes } = this.props;
    const { showDialog } = this.state;
    const { t } = this.context;

    return (
      <>
        <ErgoElementsContext.Consumer>
          {({ idGenerator }) => (
            <SvgIcon
              idGenerator={idGenerator}
              viewBox="0 0 160 42"
              title={t('content.main-title')}
              width={65}
              height={20}
              g={logoIcon}
              onClick={this.clickHandler}
              className={classes.logo}
            />
          )}
        </ErgoElementsContext.Consumer>
        <Modal
          open={showDialog}
          dismissible
          onDismiss={this.cancelHandler}
          backdropDismissesModal
        >
          <div>
            {t('share.confirmationLeavePage')}
          </div>
          <div className="esc_grid">
            <div className={`${classes.buttonsWrapper} esc_grid__wrapper`}>
              <div className="esc_col esc_col-12  esc_col-s-6 esc_col-m-5">
                <Button width="stretch" onClick={this.cancelHandler}>
                  {t('share.confirmationLeavePageNo')}
                </Button>
              </div>
              <div className="esc_col esc_col-12 esc_col-s-6 esc_col-m-5">
                <Button width="stretch" variant="primary" onClick={this.confirmHandler}>
                  {t('share.confirmationLeavePageYes')}
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

Logo.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  formData: PropTypes.objectOf(PropTypes.object),
};

Logo.defaultProps = {
  classes: {},
  formData: {},
};

Logo.contextTypes = {
  t: PropTypes.func,
};

const mapStateToProps = state => ({
  formData: state.formData,
});

export { Logo as LogoPlain };
export default compose(
  connect(
    mapStateToProps,
    null,
  ),
  injectJss(styles),
)(Logo);
