/* eslint-disable */
import React from 'react';
import snippetCss from './styles.jss';

const snippetHtml = `
<div class="container">
  <div class="root responsivegrid">
    <div class="aem-Grid aem-Grid--12 aem-Grid--default--12">
      <div class="promo padding-m aem-GridColumn aem-GridColumn--default--12">
        <div class="cmp-promo" id="root-promoreact_copy_JZmXq" data-color="#e3f0e6" data-cta-count="2" data-image-position="left">
          <div class="cmp-promo__image-container">
            <div class="cmp-promo__image" id="root-promoreact_copy_JZmXq-promoImage" data-is-rounded="false" data-is-hidden-mobile="false">
              <div data-asset-id="ef5e4cef-ec00-4547-8791-16391864d99c" id="image-d97062e4e3-image" class="cmp-image" itemscope itemtype="https://schema.org/ImageObject">
                <picture class="cmp-image__picture">
                  <source media="(max-width:320px)" srcset="https://assets.ergo.com/content/dam/ergo/service/papierlos-2.c218x76x1022x768.w320.dam.jpg.webp"/>
                  <source media="(max-width:480px)" srcset="https://assets.ergo.com/content/dam/ergo/service/papierlos-2.c218x76x1022x768.w480.dam.jpg.webp"/>
                  <source media="(max-width:768px)" srcset="https://assets.ergo.com/content/dam/ergo/service/papierlos-2.c218x76x1022x768.w768.dam.jpg.webp"/>
                  <source media="(max-width:912px)" srcset="https://assets.ergo.com/content/dam/ergo/service/papierlos-2.c218x76x1022x768.w768.dam.jpg.webp"/>
                  <source media="(max-width:1152px)" srcset="https://assets.ergo.com/content/dam/ergo/service/papierlos-2.c218x76x1022x768.w768.dam.jpg.webp"/>
                  <source media="(max-width:1440px)" srcset="https://assets.ergo.com/content/dam/ergo/service/papierlos-2.c218x76x1022x768.w768.dam.jpg.webp"/>
                  <img src="https://assets.ergo.com/content/dam/ergo/service/papierlos-2.c218x76x1022x768.dam.jpg" loading="lazy" class="cmp-image__image" itemprop="contentUrl" alt="Eine junge Frau strahlt in ihren Laptop hinein. "/>
                </picture>
              </div>
            </div>
          </div>
          <div class="cmp-promo__content" id="root-promoreact_copy_JZmXq-textContainer">
            <div class="cmp-promo__headline esc_simple-table cmp-text" id="root-promoreact_copy_JZmXq-headlinePrimary">
              <p>Papierlos &amp; digital werden</p>
            </div>
            <div class="cmp-promo__title esc_simple-table cmp-text" id="root-promoreact_copy_JZmXq-headlineSecondary">
              <p class="txt txt--headline txt__h2">ERGO Kundenportal</p>
            </div>
            <div class="cmp-promo__text esc_simple-table cmp-text" id="root-promoreact_copy_JZmXq-text">
              <ul class="check-list--green">
                <li><b>Ihre Verträge online</b><br />Verwalten Sie Ihre Verträge einfach und bequem</li>
              </ul>
              <ul class="check-list--green">
                <li><b>Schaden online melden </b><br />Regulieren Sie Rechnungen und Schäden schnell und unkompliziert</li>
              </ul>
              <ul class="check-list--green">
                <li><b>Digitales Postfach</b><br />Erhalten Sie Ihre Post online: direkt, übersichtlich und papierlos</li>
              </ul>
            </div>
            <div class="cmp-promo__placeholder">
              <div class="aem-react-child-components">
                <div class="cta">
                  <div class="cmp-cta" id="cn.Kupo_ZumKundenportalPromoUnten.root-promoreact_copy-container-cta_V7W1G" data-variant="primary" data-size="auto" data-width="100" data-color-scheme="regular" data-tracking-params="{&quot;trackingCallBehaviour&quot;:&quot;Off&quot;,&quot;clickType&quot;:&quot;o&quot;,&quot;trackingId&quot;:&quot;cn.Kupo_ZumKundenportalPromoUnten.root-promoreact_copy-container-cta_V7W1G&quot;}" data-phone-set-in-dialog>
                    <a action="link intern" class="cmp-cta__link" href="https://kunde-s.ergo.de/meineversicherungen/L0/Pages/Accountmanager/Registration.aspx?vu=ergo&intcid=9000928" rel="noopener" aria-label="Jetzt registrieren">
                      <span class="cmp-cta__label">Jetzt registrieren</span>
                    </a>
                  </div>
                </div>
                <div class="cta">
                  <div class="cmp-cta" id="cn.Kupo_ZumKundenportalPromoUnten.root-promoreact_copy-container-cta_copy_V7W1G" data-variant="secondary" data-size="auto" data-width="100" data-color-scheme="regular" data-tracking-params="{&quot;trackingCallBehaviour&quot;:&quot;Off&quot;,&quot;clickType&quot;:&quot;o&quot;,&quot;trackingId&quot;:&quot;cn.Kupo_ZumKundenportalPromoUnten.root-promoreact_copy-container-cta_copy_V7W1G&quot;}" data-phone-set-in-dialog>
                    <a action="link intern" class="cmp-cta__link" href="https://www.ergo.de/de/Service/Kundenbereich?intcid=9000929" rel="noopener" aria-label="Mehr erfahren">
                      <span class="cmp-cta__label">Mehr erfahren</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
`;

const KundenportalStatic = () => (
  <>
    <style>{snippetCss}</style>
    <div dangerouslySetInnerHTML={{ __html: snippetHtml }} />
  </>
);

export default KundenportalStatic;
