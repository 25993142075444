import {
  claimsKeys,
  getDate,
  isPersonalInjury,
  isVehicleDamage,
  isItemDamage,
  isCompany,
  getCountryName,
  compareValues,
} from './formData';
import { getCarMapValues } from '../../services/claimData/claimData.helpers';
import { getSummaryData } from '../pdfHelpers';

export function getPolicyNumber() {
  try {
    const raw = (window.envConfig && window.envConfig.POLICY_NUMBERS) || '{}';
    return JSON.parse(raw);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('Parse error POLICY_NUMBERS', err);
    return {};
  }
}

export const circumstance = {
  DRIVE_UP: 'Auffahren',
  PARKING: 'Ein- und Ausparken',
  DISREGARD: 'Mißachtung der Vorfahrt',
  BENDING: 'Abbiegen',
  SHUNTING: 'Rangieren und Rückwärtsfahren',
  OVERTAKING: 'Überholvorgang',
  LANE_CHANGE: 'Spurwechsel',
  DIFFERENT: 'Sonstiges',
};

export const guilt = {
  me: 'VN',
  opponent: 'Unfallgegner',
  'opponent-and-me': 'Unfallgegner und VN',
};

export const whatHappenedValues = {
  PERSONAL_INJURY: 'KFZ Personenschaden',
  LIABILITY: 'KFZ Haftpflicht',
  LIABILITY_PERSONAL_INJURY: 'KFZ Haftpflicht/Personenschaden',
};

export const getHeadingData = data => {
  const firstName = data[claimsKeys.owner.FIRST_NAME];
  if (isCompany(claimsKeys.owner.SALUTATION)(data)) {
    return firstName;
  }
  const lastName = data[claimsKeys.owner.LAST_NAME];

  return `${firstName} ${lastName}`;
};

const getAddressData = ({
  [claimsKeys.owner.ADDRESS]: address,
  [claimsKeys.owner.POST_CODE]: postCode,
  [claimsKeys.owner.CITY]: city,
  [claimsKeys.owner.COUNTRY_CODE]: country,
}) => ({
  address,
  postCode,
  city,
  country: getCountryName(country),
});

export const getContactDetails = data => {
  const firstName = data[claimsKeys.owner.FIRST_NAME];
  const lastName = data[claimsKeys.owner.LAST_NAME];
  const phone = data[claimsKeys.owner.PHONE];
  const email = data[claimsKeys.owner.EMAIL];
  const address = getAddressData(data);

  return {
    firstName,
    lastName,
    phone,
    email,
    address,
    isCompany: isCompany(claimsKeys.owner.SALUTATION)(data),
  };
};

const getCircumstance = kindOfDamage => circumstances => (
  kindOfDamage === circumstance.DIFFERENT ? circumstances : kindOfDamage
);

export const getIncidentDetails = ({
  [claimsKeys.DATE]: date,
  [claimsKeys.LOCATION]: location,
  [claimsKeys.COUNTRY_CODE]: country,
  [claimsKeys.KIND_OF_DAMAGE]: kindOfDamage,
  [claimsKeys.CIRCUMSTANCES]: circumstances,
  [claimsKeys.thirdParty.VEHICLE_DAMAGE]: vehicleDamage,
  [claimsKeys.owner.LICENSE_NUMBER]: licenseNumber,
  [claimsKeys.PRIVATE_VEHICLE]: privateVehicle,
  [claimsKeys.LEASED_FINANCED]: leasedFinanced,
}) => ({
  circumstance: getCircumstance(kindOfDamage)(circumstances),
  date: getDate(date),
  location,
  country: getCountryName(country),
  isVehicleDamage: compareValues(vehicleDamage),
  licenseNumber,
  privateVehicle,
  isLeasedFinanced: compareValues(leasedFinanced),
});

export const getPoliceDetails = ({
  [claimsKeys.POLICE]: police,
  [claimsKeys.DEPARTMENT]: department,
  [claimsKeys.CASE_NUMBER]: caseNumber,
  [claimsKeys.ANY_WITNESS]: anyWitness,
}) => ({
  isPolice: compareValues(police),
  department,
  caseNumber,
  isAnyWitness: compareValues(anyWitness),
});

export const getWitnessesDetails = data => {
  const arrayOfWitnessKey = ['firstWitness', 'secondWitness', 'thirdWitness'];
  const witnesses = [];
  arrayOfWitnessKey.forEach(k => {
    if (data[claimsKeys[k].FIRST_NAME]) {
      const witness = claimsKeys[k];
      witnesses.push({
        firstName: data[witness.FIRST_NAME],
        lastName: data[witness.LAST_NAME],
        phone: data[witness.PHONE],
      });
    }
  });
  return witnesses;
};

export const getCarMapInfo = (data) => (
  Object.keys(claimsKeys.owner.CAR_MAP)
    .filter(key => compareValues(data[key]))
    .map(key => getCarMapValues(claimsKeys.owner.CAR_MAP[key]))
    .join(', ')
);

export const getVehicleDamageDetails = ({
  [claimsKeys.thirdParty.VEHICLE_DAMAGE]: vehicleDamage,
  [claimsKeys.owner.LICENSE_NUMBER]: licenseNumber,
  [claimsKeys.thirdParty.VEHICLE_TYPE]: vehicleType,
  [claimsKeys.owner.DESCRIBE_DAMAGE]: describeDamage,
  [claimsKeys.IS_REPAIRED]: repaired,
}) => ({
  isVehicleDamage: compareValues(vehicleDamage),
  licenseNumber,
  isPkwVehicleType: vehicleType === 'PKW',
  describeDamage,
  isRepaired: compareValues(repaired),
});

export const getDamageOfAccidentDetails = ({
  [claimsKeys.thirdParty.PERSONAL_INJURY]: personalInjury,
  [claimsKeys.thirdParty.PERSONAL_INJURY_DESC]: personalInjuryDesc,
  [claimsKeys.thirdParty.ITEM_DAMAGE]: itemDamage,
  [claimsKeys.thirdParty.ITEM_DAMAGE_DESC]: itemDamageDesc,
  [claimsKeys.thirdParty.LICENSE_NUMBER]: licenseNumber,
  [claimsKeys.collisionAbroad.thirdParty.COUNTRY_CODE]: country,
}) => ({
  isPersonalInjury: compareValues(personalInjury),
  personalInjuryDesc,
  isItemDamage: compareValues(itemDamage),
  itemDamageDesc,
  licenseNumber,
  country: getCountryName(country),
});

export const getPayingOutDetails = ({
  [claimsKeys.IS_REPAIRED]: repaired,
  [claimsKeys.IBAN_NAME]: ibanName,
  [claimsKeys.IBAN]: iban,
  [claimsKeys.INVOICES]: invoice,
  [claimsKeys.REPAIR_SHOP_NAME]: repairShopName,
  [claimsKeys.WORKSHOP_PHONE]: workshopPhone,
}) => ({
  isRepaired: compareValues(repaired),
  ibanName,
  iban,
  invoice,
  repairShopName,
  workshopPhone,
});

export const getWhatHappened = data => {
  let whatHappenedVal = whatHappenedValues.PERSONAL_INJURY;
  const liabilityArray = [
    !isPersonalInjury(data) && isVehicleDamage(data) && !isItemDamage(data),
    !isPersonalInjury(data) && !isVehicleDamage(data) && isItemDamage(data),
    !isPersonalInjury(data) && isVehicleDamage(data) && isItemDamage(data),
  ];
  const liabilityPersonalArray = [
    isPersonalInjury(data) && isVehicleDamage(data) && !isItemDamage(data),
    isPersonalInjury(data) && !isVehicleDamage(data) && isItemDamage(data),
    isPersonalInjury(data) && isVehicleDamage(data) && isItemDamage(data),
  ];

  if (liabilityArray.includes(true)) {
    whatHappenedVal = whatHappenedValues.LIABILITY;
  }
  if (liabilityPersonalArray.includes(true)) {
    whatHappenedVal = whatHappenedValues.LIABILITY_PERSONAL_INJURY;
  }

  return { [claimsKeys.WHAT_HAPPENED]: whatHappenedVal };
};

export const getPageType = data => {
  let pageTypeVal = claimsKeys.DAMAGE_REPORT;
  // Only when we have a personal injury without any other options
  if (isPersonalInjury(data)) {
    pageTypeVal = claimsKeys.PERSON_DAMAGE_REPORT;
  }
  return { [claimsKeys.PAGE_TYPE]: pageTypeVal };
};

export const getAdditionalData = data => ({
  additionalData: {
    firstName: data[claimsKeys.owner.FIRST_NAME],
    lastName: data[claimsKeys.owner.LAST_NAME],
    email: data[claimsKeys.owner.EMAIL],
    isLiability: false,
  },
  summary: getSummaryData(),
});
